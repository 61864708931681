<template>
  <div class="header-com">
    <div class="wrap">
      <div class="left-area">
        SerCrédito
      </div>
      <div class="right-area">
        <ul class="list">
          <router-link v-for="(item,index) in currentData" :key='index' tag="li" class="item" :to="item.path">{{item.name}}</router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentData:[
        {name:'Inicio',path:'/home'},
        {name:'Privacidad',path:'/policy'},
        {name:'Usuario',path:'/user'},
      ],
      selectFlag:false,
    };
  },
  mounted(){
  },
  methods: {
    showSelect() {
      this.selectFlag = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-com {
  width: 100%;
  background: #1b1f1f;
  .wrap {
    display: flex;
    height: 80px;
    line-height: 80px;
    align-items: center;
    .left-area {
      padding-left: 110px;
      font-size: 21px;
      font-weight: bold;
      font-family: DINPro, DINPro;
      color: #fff;
      img{
        vertical-align: middle;
      }
    }
   
    .right-area {
      flex: 1;
      text-align: right;
      .list {
        padding-right: 76px;
        .item {
          display: inline-block;
          margin-right: 49px;
          color: #fff;
          text-align: left;
          font-size: 16px;
          cursor: pointer;
          &.router-link-active {
            font-weight: bold;
            color: #60F043;
          }
        }
        .selct-area {
          box-sizing: border-box;
          position: relative;
          display: inline-block;
          width: 104px;
          height: 32px;
          line-height: 32px;
          color: #fff;
          border-radius: 3px 3px;
          background: rgba(255, 255, 255, 0.3);
          font-size: 14px;
          text-align: left;
          padding-left: 6px;
          cursor: pointer;
          .text {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            padding-left: 6px;
            .icon {
              position: absolute;
              top: 12px;
              right: 16px;
              width: 0;
              height: 0;
              border-width: 6px;
              border-style: solid;
              border-color: #fff transparent transparent transparent;
            }
          }
          .select-list {
            box-sizing: border-box;
            width: 104px;
            position: absolute;
            left: 0;
            top: 32px;
            background: rgba(255, 255, 255, 0.3);
            padding-left: 6px;
            .select-item {
              box-sizing: border-box;
              font-size: 14px;
              padding-left: 6px;
            }
          }
        }
      }
    }
  }
}
</style>