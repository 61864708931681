<template>
  <div class="home-page">
    <myHead></myHead>
    <div class="time-section">
      <img class="img" width="100%" src="../assets/home_bg.png" />
      <div class="fixed">
        <!-- <div class="title">Préstamos Rápidos y Confiables</div> -->
        <div class="info">
          <img src="../assets/title1.png" alt="">
          es una innovadora plataforma de préstamos diseñada para ofrecer soluciones financieras accesibles y rápidas a los ciudadanos de América Latina. Con un enfoque centrado en el cliente, SerCrédito proporciona una gama de productos crediticios adaptados a diversas necesidades y perfiles financieros, asegurando así que más personas puedan acceder a los recursos necesarios para mejorar su calidad de vida y alcanzar sus metas.
        </div>
      </div>
    </div>
    <div class="body">
      <div class="item">
        <div class="left">
          <div class="num">01</div>
          <div class="tit">Variedad de Préstamos</div>
          <div class="info">Préstamos Personales: Diseñados para cubrir gastos imprevistos, consolidar deudas o financiar proyectos personales.</div>
          <div class="info">Préstamos para Negocios: Orientados a emprendedores y pequeños empresarios que buscan expandir o mejorar sus negocios.</div>
        </div>
        <img src="../assets/img1.png" alt="">
      </div>
      <div class="item">
        <img src="../assets/img2.png" alt="">
        <div class="left">
          <div class="num">02</div>
          <div class="tit">Acceso Rápido y Sencillo</div>
          <div class="info">Proceso de Solicitud en Línea: Todo el proceso, desde la solicitud hasta la aprobación, se realiza de manera completamente digital, facilitando el acceso a los préstamos desde cualquier lugar.</div>
          <div class="info">Aprobación Rápida: Gracias a nuestro avanzado sistema de evaluación crediticia, podemos ofrecer respuestas rápidas a las solicitudes.</div>
        </div>
      </div>
      <div class="item">
        <div class="left">
          <div class="num">03</div>
          <div class="tit">Tasas de Interés Competitivas</div>
          <div class="info">Transparencia: SerCrédito se compromete a ofrecer tasas de interés claras y sin costos ocultos, permitiendo a los clientes conocer de antemano los términos de su préstamo.</div>
          <div class="info">Tasas Ajustables: Basadas en el perfil de riesgo del solicitante, asegurando que cada cliente obtenga una oferta justa.</div>
        </div>
        <img src="../assets/img3.png" alt="">
      </div>

      <div class="bottom">
        <img src="../assets/img4.png" alt="">
      </div>
    </div>
    <myFoot />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";

export default {
  data() {
    return {
      positive: "absolute",
    };
  },
  created() {
    console.log(this.language);
  },
  methods: {
    changeLanguage(lang) {
      this.language = lang;
    },
  },
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
@keyframes bounce-down {
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.home-page {
  position: relative;

  .time-section {
    position: relative;
    .img {
      display: block;
      max-width: 100%;
      border: none;
      outline: none;
    }
    .fixed {
      position: absolute;
      width: 35%;
      height: 100%;
      left: 15%;
      top: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .title {
        font-family: DINPro, DINPro;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        line-height: 60px;
        text-align: left;
        font-style: normal;
      }
      img {
        width: 400px;
        display: block;
      }
      .info {
        font-family: DINPro, DINPro;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 30px;
        text-align: left;
        font-style: normal;
      }
    }
  }
  .body {
    width: 100%;
    padding: 60px 100px;
    box-sizing: border-box;

    .item {
      margin: 40px 0px;
      display: flex;
      justify-content: space-between;

      .left {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .num {
          font-family: Helvetica;
          font-size: 50px;
          color: rgba(39,39,39,0.12);
          letter-spacing: 7px;
          text-align: left;
          font-style: normal;
          text-transform: uppercase;
        }
        .tit {
          margin: 25px 0px;
          font-family: ACaslonPro, ACaslonPro;
          font-weight: 400;
          font-size: 20px;
          color: #272727;
          line-height: 34px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
          text-transform: uppercase;
        }
        .info {
          font-family: ACaslonPro, ACaslonPro;
          font-weight: 400;
          font-size: 16px;
          color: #4B4B4B;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          &:last-child {
            margin-top: 10px;
          }
        }
      }

      img {
        width: 40%;
      }
    }
    .bottom {
      margin-top: 100px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
