<template>
  <div class="py-page">
    <myHead></myHead>
    <div class="banner">
      <img src="../assets/policy_bg.png" style="width: 100%;" alt="">
      <div class="fixed">
        <img src="../assets/title2.png" alt="">
      </div>
    </div>
    <div class="card cardpolicy">
      <div class="policycontent">
        <div>Nos tomamos muy en serio la salvaguarda de la privacidad de nuestros usuarios. Por lo tanto, nuestra Política de Privacidad tiene como objetivo brindarle información detallada sobre cómo gestionamos y protegemos la información y datos personales de aquellos que utilizan nuestra aplicación y/o sitio web, pertenecientes a SerCrédito. Le recomendamos encarecidamente que lea detenidamente y comprenda en su totalidad nuestra Política de Privacidad antes de utilizar nuestros servicios.</div>
        <div>Al acceder a nuestra Plataforma o hacer uso de cualquier servicio que ofrecemos, y al aceptar los términos descritos en esta Política, confirma su consentimiento y comprensión sobre cómo manejamos la información personal. Valoramos profundamente su privacidad y nos comprometemos a mantener la confidencialidad y seguridad de sus datos.</div>
        <div>Si tiene alguna pregunta o inquietud con respecto a nuestra Política de Privacidad, no dude en ponerse en contacto con nosotros. Agradecemos la confianza que ha depositado en SerCrédito.</div>
        <div>&nbsp;</div>
        <div class="title1">1.Nuestra Responsabilidad en Relación a los Datos Personales </div>
        <div>En SerCrédito, cumplimos de manera estricta con la legislación vigente en lo que concierne a la captura, almacenamiento, divulgación y uso de sus datos personales. Esto abarca aspectos como el acceso, la administración, la transmisión y la eliminación de estos datos.</div>
        <div>&nbsp;</div>
        <div class="title1">2.Solicitudes de Autorización </div>
        <div>Con el propósito de mejorar nuestros servicios y brindarle la mejor experiencia posible, solicitamos su consentimiento para la recolección y utilización de ciertos datos personales. Estos datos son gestionados de manera responsable y protegidos a través de procesos seguros de transmisión a nuestros servidores:</div>
        <div><li><b style="color: #4472c4;">Permisos del Dispositivo:</b> Para llevar a cabo la implementación integral de la detección de seguridad en el entorno de red, el control de riesgos comerciales, la salvaguardia de las cuentas y las medidas antifraude, hemos integrado procedimientos específicos en diversas fases, como el registro/inicio de sesión, la activación de cuotas para productos de préstamo y el proceso de préstamo de dinero. Durante el registro o inicio de sesión, así como en la activación de cuotas y préstamos, se ejecutan verificaciones para asegurar la legitimidad del estado de inicio de sesión y prevenir posibles ataques. Es importante destacar que sus datos son cifrados y transmitidos de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue">https://gateway.sercredito.com</b>.</li></div>
        <div><li><b style="color: #4472c4;">Autoridad de Posicionamiento:</b> Con el objetivo de resguardar la seguridad de su cuenta y llevar a cabo el control de riesgos comerciales, las instituciones financieras definen la zona de acceso, la cual se activa y se verifica durante el proceso de habilitación y endeudamiento del límite de préstamo. Durante estas fases, se implementan medidas de seguridad para garantizar la protección de sus datos, que son cifrados y transmitidos de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue">https://gateway.sercredito.com</b>.</li></div>
        <div><li><b style="color: #4472c4;">Permisos de Cámara:</b> Durante el proceso de reconocimiento facial y la verificación de tarjetas de identificación y bancarias, se pueden utilizar los permisos de la cámara para capturar imágenes en tiempo real y procesar tanto imágenes como videos. Este método se emplea con el propósito de fortalecer la autenticación y la seguridad en el proceso de verificación.</li></div>
        <div><li><b style="color: #4472c4;">Permiso de Calendario:</b> Implementado en situaciones de recordatorio para el pago del día de la factura y alerta de inicio de sesión, se lleva a cabo un llamado en ambas instancias. Este mecanismo se activa con el objetivo de recordar a los usuarios sobre el vencimiento de pagos o proporcionar alertas importantes relacionadas con el inicio de sesión en el sistema.</li></div>
        <div><li><b style="color: #4472c4;">Permisos de SMS:</b> Con el fin de generar informes crediticios, evaluar límites de préstamos, prevenir el fraude y garantizar la seguridad de la cuenta, se realiza un proceso de llamada durante la activación y endeudamiento del límite de préstamo. Recopilaremos todo su REGISTRO de SMS, que nos proporcionan información crucial para la evaluación de sus deudas y transferencias pendientes, permitiéndonos generar un puntaje crediticio preciso. Es importante destacar que sus datos se cifran y transmiten de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue">https://gateway.sercredito.com</b>.</li></div>
        <div>Permiso de Registros de Llamadas: Asegúrese de que la aplicación esté correctamente instalada en su dispositivo y proceda a enviar la verificación dinámica de contraseña. Realizamos un seguimiento de todo el historial de llamadas de nuestros usuarios, específicamente con el propósito de la verificación dinámica de contraseñas. Es importante mencionar que sus datos se cifran y transmiten de manera segura a nuestros servidores a través de nuestro sitio web <b style="color: blue">https://gateway.sercredito.com</b>.</div>
        <div>&nbsp;</div>
        <div class="title1">3.Protección de la Información</div>
        <div>Aplicamos rigurosas medidas de seguridad físicas, administrativas y técnicas para proteger su información personal. Utilizamos la encriptación para mantener la privacidad de sus datos y aseguramos una transmisión segura a nuestro servidor.</div>
        <div>&nbsp;</div>
        <div class="title1">4.Cookies</div>
        <div>Nuestra aplicación puede utilizar cookies con el fin de mejorar nuestros servicios. Usted tiene la opción de aceptar o rechazar estas cookies según su preferencia.</div>
        <div>&nbsp;</div>
        <div class="title1">5.Proveedores de Servicios</div>
        <div>Colaboramos con terceros para facilitar y mejorar nuestros servicios. Estos terceros tienen acceso a sus Datos Personales, pero están comprometidos a salvaguardar su privacidad.</div>
        <div>&nbsp;</div>
        <div class="title1">6.Contacto con Nosotros</div>
        <div>Si tiene alguna pregunta, comentario o sugerencia relacionada con nuestra Política de Privacidad, le invitamos cordialmente a ponerse en contacto con nosotros. Estamos a su disposición para atender sus inquietudes y proporcionar la información adicional que pueda necesitar. Puede comunicarse con nosotros a través de los siguientes medios:</div>
        <div class="indent">Correo Electrónico: <b>customerService@sercredito.com</b></div>
        <div class="indent">Horario de Oficina: De lunes a sábado, de 8:30 a 17:00</div>
        <div class="indent">Página Web de la Empresa: <b>https://www.sercredito.com</b></div>
        <div>&nbsp;</div>
        <div>Esta Política de Privacidad entrará en vigor a partir de su aceptación y estará sujeta a revisiones periódicas. Le recomendamos que la consulte regularmente para mantenerse informado acerca de cómo preservamos y protegemos su privacidad. Nos comprometemos a actualizar y mejorar de manera continua nuestras prácticas de privacidad, buscando garantizar la máxima transparencia y seguridad en el manejo de sus datos personales. Su comprensión y adhesión a esta política son esenciales para asegurar una experiencia confiable y segura al utilizar nuestros servicios en la Plataforma de SerCrédito. Si tiene alguna pregunta o inquietud, no dude en ponerse en contacto con nosotros. Agradecemos su confianza y colaboración en la protección de su privacidad.</div>
        <div>&nbsp;</div>
      </div>
    </div>
    <myFoot :positive="positive" />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";
export default {
  data() {
    return {
      positive: "absolute",
    };
  },

  methods: {},
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.py-page {
  position: relative;
  padding-bottom: 100px;
  .banner {
    position: relative;
    .fixed {
      width: max-content;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      img {
        width: 60%;
      }
    }
  }
  .cardpolicy {
    padding: 20px 0;
    background: #f6f6f6;
    .policycontent {
      width: 80%;
      margin: 0 auto;
      .title {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        margin: 40px 0;
      }
      .title1 {
        font-weight: bold;
        font-size: 22px;
        margin: 10px 0;
      }
      p,
      div {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a0404;
        line-height: 34px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
