<template>
  <div class="footer-com" :style="{ position: positive }">
    <img src="../assets/logo.png" alt="">
    <span>SerCrédito</span>
    
  </div>
</template>

<script>
export default {
  props: ["positive"],
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.footer-com {
  width: 100%;
  height: 100px;
  background: #06250F;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-family: Ebrima;
    font-size: 24px;
    color: #fff;
  }
  img {
    height: 50px;
    margin-right: 20px;
  }
  
}
</style>